import { Component } from '@angular/core';
import { JustCallSettingsDto, EmailSettingsService } from '@proxy/email';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-just-call-settings',
  templateUrl: './just-call-settings.component.html',
  styleUrls: ['./just-call-settings.component.scss']
})
export class JustCallSettingsComponent {
  justCallSettings: JustCallSettingsDto = {};

  constructor(private emailSettingsService: EmailSettingsService) {
  }

  ngOnInit(): void {
    this.onLoad();
  }

  async onLoad() {
    this.justCallSettings = await lastValueFrom(this.emailSettingsService.getJustCallSettings());
  }

  async onSettingsSave() {
    await lastValueFrom(this.emailSettingsService.setJustCallSettingsByJustCallSettings(this.justCallSettings));
  }
}
