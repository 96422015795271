

<section class="ttb-header-top d-flex flex-md-row flex-column justify-content-between px-1 py-1 gap-1"
  style="{{ isTestSite() ? 'background: #b02828;' : '' }}">
  <div class="d-flex flex-row gap-2 align-items-center">
    <span class="d-block text-md-start text-center">Tom's Tool Box</span>

    <div *ngIf="isTestSite()"
      style="color: white;font-weight: bold;font-size: 15px;outline: 1px solid;height: fit-content;">
      TEST - <a class="text-white" href="https://ttb.netkinetix.com/ttbtest.apk" style="text-decoration: none !important;"><i class="fa fa-download"></i> APK</a>
    </div>


    <a class="top-bar-logout-button align-self-center d-block d-md-none ms-auto" (click)="logout()"
      style="{{ isTestSite() ? 'background: #aa5353;' : '' }}">Logout</a>
  </div>
  <div *ngIf="isLoggedIn" class="d-flex flex-md-row flex-row /*column*/ gap-1 gap-md-5" style="justify-content: space-between;">
    
    <!--<style>
      @keyframes pulse {
            0% {
                transform: scale(1);
            }

            50% {
                transform: scale(1.1);
            }

            100% {
                transform: scale(1);
            }
        }

        body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .pulse {
            border-radius: 50%;
            animation: pulse 2s infinite;
            color: #e19e9e;
            font-weight: bold;
        }
    </style>-->
    
    <span class="d-block text-md-end text-center"
      style="position: relative;">
      
      <div style="cursor: pointer;user-select: none;color: #fff500;"
        (click)="toggleNotifications($event)">

        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell-fill" viewBox="0 0 16 16">
          <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901"/>
        </svg>
        
        {{ pendingNotifications }}

      </div>

      <style>
        #notificationsIframe {
          position: absolute;
          width: 400px;
          height: 600px;
          z-index: 100;
          box-shadow: 0 0 0.2rem 0.2rem #00000061;
          left: 0;
          top: 40px;
          background: black;
        }

        @media screen and (max-width: 768px) {
          #notificationsIframe {
            width: 100%;
            height: calc(100vh - 10rem);
            position: fixed;
          }
        }
      </style>

      <iframe *ngIf="externalAuthToken" [src]="getIframePath() | safe"
        style=""
        class="{{ notificationsVisible ? '' : 'd-none' }}"
        id="notificationsIframe">
      </iframe>

      <!--
      <span style="cursor: pointer;
                   position: absolute;
                   left: 374px;
                   top: 40px;
                   z-index: 101;
                   color: black;
                   font-size: 20px;"
            class="{{ notificationsVisible ? '' : 'd-none' }}"
            (click)="toggleNotifications($event)">
        &times;
      </span>
      -->
    
    </span>
    <span class="d-block text-md-end text-center">{{ location ?? '' }}</span>
    <span class="d-block text-md-end text-center">Hello, {{ firstName ?? '' }}</span>
    <a class="top-bar-logout-button align-self-center d-none d-md-block" (click)="logout()"
      style="{{ isTestSite() ? 'background: #aa5353;' : '' }}">Logout</a>
  </div>
</section>

<section class="ttb-header-bottom d-flex flex-md-row flex-column align-items-center px-3 py-1">
  <a href="/" title="Home" style="text-decoration: none !important;
                                  display: flex;
                                  flex-direction: row;
                                  align-items: end;
                                  gap: 10px;">
    <img
      src="../../assets/images/logo/honey-do-service-logo.png"
      alt="Tom's Tool Box"
      height="60"
    />
  </a>
  <div id="ttb-header-bottom-title" class="w-100 fs-2 text-center">System Dashboard</div>


  <div *ngIf="isTestSite()" class="me-3">
    <span class="alert alert-danger py-1 px-2 text-white d-none d-md-block my-auto" style="white-space: nowrap;">
      <center>
        TEST<br />
        ENVIRONMENT
      </center>
    </span>
  </div>



  <div class="d-none">

        <button
          class="top-bar-button me-2"
          type="button"
          id="top-bar-closing-ratio-report-button"
          (click)="routeClosingRatio()"
        >
          Closing Ratio Report
        </button>

  </div>


  <button
    *ngIf="isAdmin"
    class="top-bar-button"
    id="top-bar-calendar-button"
    type="button"
    (click)="routeCalendar()">
    Calendar
  </button>
  <button
    class="top-bar-button"
    id="top-bar-customer-center-button"
    type="button"
    (click)="routeCustomers()">
    Client Partner Center
  </button>
</section>