<h2>ConnectUC SMS</h2>
<hr class="my-2" />

<!--
<div class="alert alert-info text-white mb-3">
    Tokens from Azure Portal have a lifetime of <strong>up to</strong> 2 years.
    Set a reminder to come back and update these tokens near to expiration.
</div>
-->

<table class="mt-1">
    <colgroup>
        <col style="width: 200px;" />
        <col style="width: 500px;" />
    </colgroup>
    <tbody>

        <tr>
            <td>API Key:</td>
            <td>
                <!--type="text"-->
                <input type="password" class="form-control" [(ngModel)]="justCallSettings.apiKey" />
            </td>
        </tr>
        
        <tr style="display: none;">
            <td>API Secret:</td>
            <td>
                <input type="password" class="form-control" [(ngModel)]="justCallSettings.apiSecret" />
            </td>
        </tr>
        
        <tr>
            <td>From Phone Number:</td>
            <td>
                <input type="text" class="form-control" [(ngModel)]="justCallSettings.fromPhoneNumber" />
            </td>
        </tr>
        
    </tbody>
</table>

<hr class="my-3" />

<button type="submit" class="btn btn-primary" (click)="onSettingsSave()">
    <i class="me-1 fa fa-save" ng-reflect-ng-class="fa fa-save"></i> Save
</button>