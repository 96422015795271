import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FranchiseSalesPlaceholdersComponent } from './franchise-sales-placeholders.component';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { NgbCollapseModule, NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { SharedModule } from '../shared/shared.module';
import { PageModule } from '@abp/ng.components/page';
import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

export const FRANCHISE_SALES_PLACEHOLDERS_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/franchise-sales-placeholders',
        iconClass: 'fas fa-calendar',
        name: 'Sales Events',
        layout: eLayoutType.application,
        requiredPolicy: 'TTB.Routing.Users',
        order: 9,
        group: 'Franchisee Administration'
      },
    ]);
  };
}

const routes: Routes = [
  {
    path: '',
    component: FranchiseSalesPlaceholdersComponent
  }
];

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  
}

@NgModule({
  declarations: [
    FranchiseSalesPlaceholdersComponent,
    SafePipe
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    ThemeSharedModule,
    CommercialUiModule,
    NgxValidateCoreModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    SharedModule,
    PageModule
  ]
})
export class FranchiseSalesPlaceholdersModule { }
