import { AuthService, ConfigStateService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  FranchiseeUserService } from '@proxy/franchisee-users';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-ttb-header',
  templateUrl: './ttb-header.component.html',
  styles: [],
})
export class TtbHeaderComponent implements OnInit {
  externalAuthToken: any;
  pendingNotifications: number = 0;
  notificationsVisible: boolean = false;

  public isLoggedIn = false;
  public firstName: string;
  public location: string;
  public isAdmin = false;

  constructor(private _config: ConfigStateService,
    private _authServ: AuthService,
    private _franchiseeUserService: FranchiseeUserService,
    private router: Router,
    private route: ActivatedRoute) { }


  isTestSite() {
    return (window?.location.hostname.startsWith('apptest.') || window?.location.hostname.endsWith('.netkinetix.com') || window?.location.hostname === 'localhost');
  }

  ngOnInit() {

    window.onmessage = (e) => {
      if (e.data == "click") {
        this.hideNotifications();
      } else if (e.data == "close") {
        this.hideNotifications();
      } else {
        try {
          var parsed = JSON.parse(e.data);
          if (parsed && parsed.count !== undefined)
          {
            this.pendingNotifications = parsed.count;
          }
        } catch (ex) {
        }
      }
    };

    document.addEventListener("click", (e) => {
      this.hideNotifications();
    });

    this._config.getOne$('currentUser').subscribe(currentUser => {
      if (currentUser['isAuthenticated']) {

        this.onLoad();

        this.isAdmin = !currentUser.roles.includes('Franchisee');
        this.isLoggedIn = true;
        this._franchiseeUserService.getWithNavigationProperties(currentUser.id).subscribe(result => {
          this.firstName = currentUser.name.split(' ')[0];
          let location = '';
          if (result?.franchise?.name)
          {
            location += `${result.franchise.name}, `;
          }
          if (result?.franchise?.state) {
            location += `${result.franchise.state}`;
          }
          this.location = location;
        });
      }
    });
  }

  async onLoad() {
    this.externalAuthToken = await lastValueFrom(this._franchiseeUserService.createExternalAuthToken());
    this.externalAuthToken = JSON.parse(this.externalAuthToken);
  }

  showNotifications() {
    this.notificationsVisible = true;
    (document.getElementById("notificationsIframe") as any).contentWindow.postMessage("opened", "*");
  }

  hideNotifications() {
    if (!this.notificationsVisible) {
      return;
    }
    this.notificationsVisible = false;
    (document.getElementById("notificationsIframe") as any).contentWindow.postMessage("closed", "*");
  }

  toggleNotifications(e) {
    e.stopPropagation();
    if (this.notificationsVisible) {
      this.hideNotifications();
    } else {
      this.showNotifications();
    }
  }

  encodeBasePath(): string {
    const basePath = location.origin;
    const utf8Bytes = new TextEncoder().encode(basePath);

    let base64String = '';
    utf8Bytes.forEach(byte => {
      base64String += String.fromCharCode(byte);
    });
    base64String = btoa(base64String);
  
    return encodeURI(base64String);
  }

  getIframePath() {
    var basePath = environment.apis.Calendar.url;
    if (!basePath.endsWith("/"))
    {
      basePath += "/";
    }
    return `${basePath}notifications-box/${this.encodeBasePath()}/${this.externalAuthToken}`;
  }

  logout() {
    this._config.getOne$('currentUser').subscribe(currentUser => {
      if (currentUser['isAuthenticated']) {
        this._authServ.logout().subscribe(() => {
          this.isLoggedIn = false;
        });
      }
    });
  }

  routeClosingRatio() {
    this.router.navigate(['/closing-ratio-report'])
  }

  routeCustomers() {
    this.router.navigate(['/customers'])
  }

  routeCalendar() {
    this.router.navigate(['/calendar'])
  }
}
