import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { FranchiseeUserService } from '@proxy/franchisee-users';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-franchise-sales-placeholders',
  templateUrl: './franchise-sales-placeholders.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class FranchiseSalesPlaceholdersComponent implements OnInit {
  externalAuthToken: any;

  constructor(
    private readonly _franchiseeUserService: FranchiseeUserService
  ) { }

  ngOnInit(): void {
    this.onLoad();
  }

  async onLoad() {
    this.externalAuthToken = await lastValueFrom(this._franchiseeUserService.createExternalAuthToken());
    this.externalAuthToken = JSON.parse(this.externalAuthToken);
  }

  encodeBasePath(): string {
    const basePath = location.origin;
    const utf8Bytes = new TextEncoder().encode(basePath);

    let base64String = '';
    utf8Bytes.forEach(byte => {
      base64String += String.fromCharCode(byte);
    });
    base64String = btoa(base64String);
  
    return encodeURI(base64String);
  }

  getIframePath() {
    var basePath = environment.apis.Calendar.url;
    if (!basePath.endsWith("/"))
    {
      basePath += "/";
    }
    return `${basePath}sales-placeholders/${this.encodeBasePath()}/${this.externalAuthToken}`;
  }
}
