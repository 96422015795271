import type { FranchiseeUserCreateDto, FranchiseeUserDto, FranchiseeUserUpdateDto, FranchiseeUserWithNavigationPropertiesDto, GetFranchiseeUsersInput } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { IdentityRoleDto } from '../volo/abp/identity/models';

@Injectable({
  providedIn: 'root',
})
export class FranchiseeUserService {
  apiName = 'Default';
  

  create = (input: FranchiseeUserCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FranchiseeUserWithNavigationPropertiesDto>({
      method: 'POST',
      url: '/api/app/franchisee-users',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  createExternalAuthToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/app/franchisee-users/create-external-auth-token',
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FranchiseeUserDto>({
      method: 'GET',
      url: `/api/app/franchisee-users/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getAccountTypes = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdentityRoleDto[]>({
      method: 'GET',
      url: '/api/app/franchisee-users/account-types',
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetFranchiseeUsersInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<FranchiseeUserWithNavigationPropertiesDto>>({
      method: 'GET',
      url: '/api/app/franchisee-users',
      params: { filterText: input.filterText, initials: input.initials, estimator: input.estimator, officeAdmin: input.officeAdmin, foreman: input.foreman, craftsman: input.craftsman, franchiseeAdmin: input.franchiseeAdmin, generalManager: input.generalManager, subContractor: input.subContractor, isActive: input.isActive, franchiseId: input.franchiseId },
    },
    { apiName: this.apiName,...config });
  

  getWithNavigationProperties = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FranchiseeUserWithNavigationPropertiesDto>({
      method: 'GET',
      url: `/api/app/franchisee-users/with-navigation-properties/${id}`,
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: FranchiseeUserUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FranchiseeUserWithNavigationPropertiesDto>({
      method: 'PUT',
      url: `/api/app/franchisee-users/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
